import { useState, useEffect } from "react";
import { Moon, Sun, House, List, X } from "@phosphor-icons/react";
import { Link } from "react-router-dom";

// Define the props interface
interface HeaderProps {
  isDarkMode: boolean;
  toggleDarkMode: () => void;
}

const Header: React.FC<HeaderProps> = ({ isDarkMode, toggleDarkMode }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  // Close mobile menu when window is resized to desktop size
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setMobileMenuOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <header className="sticky top-0 z-50 w-full flex justify-between items-center p-4 md:p-6 bg-white shadow-lg font-sans dark:bg-gray-800 dark:shadow-lg">
      <Link to="/" className="z-20">
        <House
          size={28}
          className="cursor-pointer text-gray-800 dark:text-white hover:scale-110 transition-transform duration-300"
        />
      </Link>

      {/* Desktop Navigation */}
      <nav className="hidden md:block mx-auto">
        <ul className="flex space-x-8 text-lg font-7xl">
          <li>
            <a
              href="#about"
              className="hover:text-my-blue dark:hover:text-blue-300 dark:text-white transition-colors duration-300"
            >
              about
            </a>
          </li>
          <li>
            <a
              href="#skills"
              className="hover:text-my-blue dark:hover:text-blue-300 dark:text-white transition-colors duration-300"
            >
              skills
            </a>
          </li>
          <li>
            <a
              href="#projects"
              className="hover:text-my-blue dark:hover:text-blue-300 dark:text-white transition-colors duration-300"
            >
              projects
            </a>
          </li>
        </ul>
      </nav>

      {/* Theme Toggle and Mobile Menu Button */}
      <div className="flex items-center space-x-4 z-20">
        {isDarkMode ? (
          <Sun
            size={28}
            weight={isHovered ? "fill" : "bold"}
            onClick={toggleDarkMode}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className={`cursor-pointer transition-transform duration-300 ${
              isHovered ? "scale-110" : "scale-100"
            } text-yellow-400 dark:text-yellow-300`}
          />
        ) : (
          <Moon
            size={28}
            weight={isHovered ? "fill" : "bold"}
            onClick={toggleDarkMode}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className={`cursor-pointer transition-transform duration-300 ${
              isHovered ? "scale-110" : "scale-100"
            } text-gray-800 dark:text-gray-300`}
          />
        )}

        {/* Mobile Menu Toggle */}
        <button
          className="md:hidden focus:outline-none"
          onClick={toggleMobileMenu}
          aria-label="Toggle mobile menu"
        >
          {mobileMenuOpen ? (
            <X
              size={28}
              className="text-gray-800 dark:text-white transition-transform duration-300 hover:scale-110"
            />
          ) : (
            <List
              size={28}
              className="text-gray-800 dark:text-white transition-transform duration-300 hover:scale-110"
            />
          )}
        </button>
      </div>

      {/* Mobile Navigation Overlay */}
      <div
        className={`fixed inset-0 bg-white dark:bg-gray-800 transform transition-transform duration-300 ease-in-out z-10 ${
          mobileMenuOpen ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <div className="flex flex-col items-center justify-center h-full">
          <nav className="flex flex-col items-center">
            <ul className="flex flex-col space-y-8 text-2xl">
              <li>
                <a
                  href="#about"
                  className="hover:text-my-blue dark:hover:text-blue-300 dark:text-white transition-colors duration-300"
                  onClick={closeMobileMenu}
                >
                  about
                </a>
              </li>
              <li>
                <a
                  href="#skills"
                  className="hover:text-my-blue dark:hover:text-blue-300 dark:text-white transition-colors duration-300"
                  onClick={closeMobileMenu}
                >
                  skills
                </a>
              </li>
              <li>
                <a
                  href="#projects"
                  className="hover:text-my-blue dark:hover:text-blue-300 dark:text-white transition-colors duration-300"
                  onClick={closeMobileMenu}
                >
                  projects
                </a>
              </li>
              <li>
                <Link
                  to="/resume"
                  className="px-6 py-3 bg-my-blue text-white font-medium rounded-md shadow-md hover:bg-blue-600 transition-colors duration-300"
                  onClick={closeMobileMenu}
                >
                  resume
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
