import React, { useState, useEffect } from "react";
import { ArrowLeft, Download, FilePdf } from "@phosphor-icons/react";
import { Link } from "react-router-dom";

const ResumePage: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [loadingProgress, setLoadingProgress] = useState(0);

  // Simulate loading progress
  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (isLoading) {
      interval = setInterval(() => {
        setLoadingProgress((prev) => {
          const newProgress = prev + Math.random() * 15;
          if (newProgress >= 100) {
            clearInterval(interval);
            setTimeout(() => setIsLoading(false), 300);
            return 100;
          }
          return newProgress;
        });
      }, 200);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isLoading]);

  return (
    <div className="flex flex-col min-h-screen pt-16 md:pt-20 bg-gradient-to-b from-gray-50 to-white dark:from-gray-900 dark:to-gray-800 text-black dark:text-white w-full">
      {/* Back button and heading */}
      <div className="container mx-auto px-4 py-6">
        <Link
          to="/"
          className="flex items-center text-gray-600 dark:text-gray-300 hover:text-my-blue dark:hover:text-blue-400 transition-colors mb-4"
        >
          <ArrowLeft size={20} className="mr-1" />
          Back to Home
        </Link>

        <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-6">
          <h1 className="text-3xl md:text-4xl font-bold">My Resume</h1>

          <a
            href="/Resume.pdf"
            download
            className="mt-4 md:mt-0 flex items-center px-4 py-2 bg-my-blue text-white font-medium rounded-md shadow-md hover:bg-blue-600 transition-all hover:shadow-lg"
          >
            <Download size={20} className="mr-2" />
            Download PDF
          </a>
        </div>
      </div>

      {/* PDF Viewer with Loading State */}
      <div className="flex-grow container mx-auto px-4 pb-8">
        <div className="bg-white dark:bg-gray-700 rounded-2xl shadow-xl overflow-hidden h-[75vh] relative">
          {isLoading ? (
            <div className="absolute inset-0 flex flex-col items-center justify-center bg-gray-100 dark:bg-gray-800">
              <FilePdf size={48} className="text-my-blue mb-4 animate-pulse" />
              <div className="w-56 bg-gray-200 dark:bg-gray-600 rounded-full h-3 mb-2">
                <div
                  className="bg-my-blue h-3 rounded-full transition-all duration-300"
                  style={{ width: `${loadingProgress}%` }}
                />
              </div>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Loading resume...
              </p>
            </div>
          ) : null}

          <iframe
            src="/Resume.pdf"
            className="w-full h-full"
            title="Resume PDF"
            onLoad={() => setIsLoading(false)}
          />
        </div>

        {/* Mobile-friendly message */}
        <div className="mt-6 md:hidden text-center">
          <p className="text-sm text-gray-500 dark:text-gray-400">
            For better viewing experience on mobile, please download the PDF.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ResumePage;
