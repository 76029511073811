import React, { useState, useEffect } from "react";
import JavaScriptLogo from "../assets/skills/javascript-original.svg";
import TypeScriptLogo from "../assets/skills/typescript-original.svg";
import PythonLogo from "../assets/skills/python-original.svg";
import JavaLogo from "../assets/skills/java-original.svg";
import KotlinLogo from "../assets/skills/kotlin-original.svg";
import SwiftLogo from "../assets/skills/swift-original.svg";
import MySQLLogo from "../assets/skills/mysql-original.svg";
import MongoDBLogo from "../assets/skills/mongodb-original.svg";
import HTMLLogo from "../assets/skills/html5-original.svg";
import CSSLogo from "../assets/skills/css3-original.svg";
import GitLogo from "../assets/skills/git-original.svg";
import ReactLogo from "../assets/skills/react-original.svg";
import NodejsLogo from "../assets/skills/nodejs-original.svg";
import FirebaseLogo from "../assets/skills/firebase-original.svg";
import TailwindLogo from "../assets/skills/tailwindcss-original.svg";
import FigmaLogo from "../assets/skills/figma-original.svg";
import BootstrapLogo from "../assets/skills/bootstrap-original.svg";
import IllustratorLogo from "../assets/skills/illustrator-plain.svg";
import PhotoshopLogo from "../assets/skills/photoshop-original.svg";
import FlutterLogo from "../assets/skills/flutter-original.svg";
import VSCodeLogo from "../assets/skills/vscode-original.svg";
import AndroidStudioLogo from "../assets/skills/androidstudio-original.svg";
import JupyterLogo from "../assets/skills/jupyter-original.svg";
import MatplotlibLogo from "../assets/skills/matplotlib-original.svg";
import PandasLogo from "../assets/skills/pandas-original.svg";
import NumpyLogo from "../assets/skills/numpy-original.svg";
import ScikitLearnLogo from "../assets/skills/scikitlearn-original.svg";
import PytorchLogo from "../assets/skills/pytorch-original.svg";
import JiraLogo from "../assets/skills/jira-original.svg";
import MaterialUILogo from "../assets/skills/materialui-original.svg";
import KaggleLogo from "../assets/skills/kaggle-original.svg";
import NextjsLogo from "../assets/skills/nextjs-original.svg";
import RLogo from "../assets/skills/r-original.svg";
import GithubbLogo from "../assets/skills/github-original.svg";
import SlackLogo from "../assets/skills/slack-original.svg";
import XDLogo from "../assets/skills/xd-plain.svg";
import NotionLogo from "../assets/skills/notion-original.svg";

// Define types
type Skill = {
  name: string;
  svgPath: string;
  category: string;
  proficiency?: number; // Optional proficiency score out of 5
};

type FilterCategory = "all" | "languages" | "frameworks" | "ml-ai" | "tools";

const SkillsSection: React.FC = () => {
  const [activeFilter, setActiveFilter] = useState<FilterCategory>("all");
  const [animateCards, setAnimateCards] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  // Combine all skills into a single array with categories and proficiency
  const allSkills: Skill[] = [
    // Languages
    {
      name: "JavaScript",
      svgPath: JavaScriptLogo,
      category: "languages",
      proficiency: 5,
    },
    {
      name: "TypeScript",
      svgPath: TypeScriptLogo,
      category: "languages",
      proficiency: 4,
    },
    {
      name: "Python",
      svgPath: PythonLogo,
      category: "languages",
      proficiency: 5,
    },
    { name: "Java", svgPath: JavaLogo, category: "languages", proficiency: 4 },
    {
      name: "Kotlin",
      svgPath: KotlinLogo,
      category: "languages",
      proficiency: 3,
    },
    {
      name: "Swift",
      svgPath: SwiftLogo,
      category: "languages",
      proficiency: 3,
    },
    {
      name: "MySQL",
      svgPath: MySQLLogo,
      category: "languages",
      proficiency: 4,
    },
    {
      name: "MongoDB",
      svgPath: MongoDBLogo,
      category: "languages",
      proficiency: 4,
    },
    { name: "HTML", svgPath: HTMLLogo, category: "languages", proficiency: 5 },
    { name: "CSS", svgPath: CSSLogo, category: "languages", proficiency: 5 },

    // Frameworks & Technologies
    {
      name: "React",
      svgPath: ReactLogo,
      category: "frameworks",
      proficiency: 5,
    },
    {
      name: "Node.js",
      svgPath: NodejsLogo,
      category: "frameworks",
      proficiency: 4,
    },
    {
      name: "Firebase",
      svgPath: FirebaseLogo,
      category: "frameworks",
      proficiency: 4,
    },
    {
      name: "Next.js",
      svgPath: NextjsLogo,
      category: "frameworks",
      proficiency: 4,
    },
    {
      name: "Tailwind CSS",
      svgPath: TailwindLogo,
      category: "frameworks",
      proficiency: 5,
    },
    {
      name: "Bootstrap",
      svgPath: BootstrapLogo,
      category: "frameworks",
      proficiency: 4,
    },
    {
      name: "Flutter",
      svgPath: FlutterLogo,
      category: "frameworks",
      proficiency: 3,
    },
    {
      name: "Material UI",
      svgPath: MaterialUILogo,
      category: "frameworks",
      proficiency: 4,
    },
    { name: "Git", svgPath: GitLogo, category: "frameworks", proficiency: 5 },

    // ML & AI
    {
      name: "Pytorch",
      svgPath: PytorchLogo,
      category: "ml-ai",
      proficiency: 4,
    },
    {
      name: "Jupyter",
      svgPath: JupyterLogo,
      category: "ml-ai",
      proficiency: 5,
    },
    { name: "R", svgPath: RLogo, category: "ml-ai", proficiency: 3 },
    {
      name: "Matplotlib",
      svgPath: MatplotlibLogo,
      category: "ml-ai",
      proficiency: 4,
    },
    { name: "Pandas", svgPath: PandasLogo, category: "ml-ai", proficiency: 5 },
    { name: "NumPy", svgPath: NumpyLogo, category: "ml-ai", proficiency: 4 },
    {
      name: "Scikit Learn",
      svgPath: ScikitLearnLogo,
      category: "ml-ai",
      proficiency: 4,
    },
    { name: "Kaggle", svgPath: KaggleLogo, category: "ml-ai", proficiency: 4 },

    // Tools
    { name: "Figma", svgPath: FigmaLogo, category: "tools", proficiency: 4 },
    {
      name: "Illustrator",
      svgPath: IllustratorLogo,
      category: "tools",
      proficiency: 4,
    },
    {
      name: "Photoshop",
      svgPath: PhotoshopLogo,
      category: "tools",
      proficiency: 3,
    },
    { name: "XD", svgPath: XDLogo, category: "tools", proficiency: 3 },
    { name: "VS Code", svgPath: VSCodeLogo, category: "tools", proficiency: 5 },
    {
      name: "Android Studio",
      svgPath: AndroidStudioLogo,
      category: "tools",
      proficiency: 4,
    },
    { name: "Jira", svgPath: JiraLogo, category: "tools", proficiency: 4 },
    { name: "Github", svgPath: GithubbLogo, category: "tools", proficiency: 5 },
    { name: "Slack", svgPath: SlackLogo, category: "tools", proficiency: 5 },
    { name: "Notion", svgPath: NotionLogo, category: "tools", proficiency: 5 },
  ];

  // Filter skills based on active category and search term
  const filteredSkills = allSkills
    .filter(
      (skill) => activeFilter === "all" || skill.category === activeFilter
    )
    .filter((skill) =>
      skill.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

  // Handle filter changes
  const handleFilterChange = (category: FilterCategory) => {
    setAnimateCards(false); // Trigger exit animation

    // Small delay to allow exit animation to complete
    setTimeout(() => {
      setActiveFilter(category);
      setAnimateCards(true); // Trigger entrance animation
    }, 300);
  };

  // Animation on scroll
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate-fade-in");
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    document.querySelectorAll(".skills-animate-on-scroll").forEach((el) => {
      observer.observe(el);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <section
      id="skills"
      className="py-16 flex justify-center items-center dark:bg-gray-800 text-black dark:text-white w-full px-4 md:px-6"
    >
      <div className="w-full max-w-7xl p-4 md:p-8 bg-gradient-to-br from-gray-50 to-gray-100 dark:from-gray-700 dark:to-gray-800 text-center rounded-3xl shadow-2xl dark:shadow-2xl">
        <h1 className="text-4xl md:text-5xl font-bold mb-8 skills-animate-on-scroll opacity-0 transition-opacity duration-700">
          my skills
        </h1>

        {/* Filter buttons and search */}
        <div className="flex flex-col md:flex-row justify-between items-center mb-8 skills-animate-on-scroll opacity-0 transition-opacity duration-700 delay-100">
          <div className="flex flex-wrap justify-center gap-2 md:gap-4 mb-4 md:mb-0">
            <button
              onClick={() => handleFilterChange("all")}
              className={`px-4 py-2 rounded-full text-sm md:text-base transition-all duration-300 ${
                activeFilter === "all"
                  ? "bg-my-blue text-white shadow-md"
                  : "bg-gray-200 dark:bg-gray-600 hover:bg-gray-300 dark:hover:bg-gray-500"
              }`}
            >
              All Skills
            </button>
            <button
              onClick={() => handleFilterChange("languages")}
              className={`px-4 py-2 rounded-full text-sm md:text-base transition-all duration-300 ${
                activeFilter === "languages"
                  ? "bg-my-blue text-white shadow-md"
                  : "bg-gray-200 dark:bg-gray-600 hover:bg-gray-300 dark:hover:bg-gray-500"
              }`}
            >
              Languages
            </button>
            <button
              onClick={() => handleFilterChange("frameworks")}
              className={`px-4 py-2 rounded-full text-sm md:text-base transition-all duration-300 ${
                activeFilter === "frameworks"
                  ? "bg-my-blue text-white shadow-md"
                  : "bg-gray-200 dark:bg-gray-600 hover:bg-gray-300 dark:hover:bg-gray-500"
              }`}
            >
              Frameworks
            </button>
            <button
              onClick={() => handleFilterChange("ml-ai")}
              className={`px-4 py-2 rounded-full text-sm md:text-base transition-all duration-300 ${
                activeFilter === "ml-ai"
                  ? "bg-my-blue text-white shadow-md"
                  : "bg-gray-200 dark:bg-gray-600 hover:bg-gray-300 dark:hover:bg-gray-500"
              }`}
            >
              ML & AI
            </button>
            <button
              onClick={() => handleFilterChange("tools")}
              className={`px-4 py-2 rounded-full text-sm md:text-base transition-all duration-300 ${
                activeFilter === "tools"
                  ? "bg-my-blue text-white shadow-md"
                  : "bg-gray-200 dark:bg-gray-600 hover:bg-gray-300 dark:hover:bg-gray-500"
              }`}
            >
              Tools
            </button>
          </div>

          {/* Search input */}
          <div className="w-full md:w-auto">
            <input
              type="text"
              placeholder="Search skills..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="px-4 py-2 rounded-full w-full md:w-56 bg-white dark:bg-gray-600 border border-gray-300 dark:border-gray-500 focus:outline-none focus:ring-2 focus:ring-my-blue dark:text-white"
            />
          </div>
        </div>

        {/* Skills Grid */}
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-2 md:gap-6 justify-center content-center skills-animate-on-scroll opacity-0 transition-opacity duration-700 delay-200">
          {filteredSkills.map((skill, index) => (
            <div
              key={skill.name}
              className={`transform transition-all duration-500 ${
                animateCards
                  ? "translate-y-0 opacity-100"
                  : "translate-y-10 opacity-0"
              }`}
              style={{ transitionDelay: `${(index % 10) * 50}ms` }}
            >
              <SkillWidget
                svgPath={skill.svgPath}
                name={skill.name}
                proficiency={skill.proficiency}
              />
            </div>
          ))}
        </div>

        {/* Empty state */}
        {filteredSkills.length === 0 && (
          <div className="py-12 text-center">
            <p className="text-lg text-gray-500 dark:text-gray-400">
              No skills found matching your search. Try a different term or
              category.
            </p>
          </div>
        )}
      </div>
    </section>
  );
};

// SkillWidget Component with Proficiency Indicator
const SkillWidget: React.FC<{
  svgPath: string;
  name: string;
  proficiency?: number;
}> = ({ svgPath, name, proficiency = 0 }) => {
  return (
    <div className="group relative flex flex-col items-center justify-center p-4 hover:bg-white dark:hover:bg-gray-600 rounded-xl transition-all duration-300 transform hover:scale-105 hover:shadow-lg">
      <img
        src={svgPath}
        alt={name}
        className="w-12 h-12 md:w-16 md:h-16 transition-transform group-hover:scale-110"
        loading="lazy"
      />
      <p className="mt-2 text-sm font-medium">{name}</p>

      {/* Proficiency indicator */}
      {proficiency > 0 && (
        <div className="w-full mt-2 flex justify-center space-x-1">
          {[...Array(5)].map((_, i) => (
            <div
              key={i}
              className={`h-1 w-2 rounded-full ${
                i < proficiency
                  ? "bg-gradient-to-r from-blue-400 to-my-blue"
                  : "bg-gray-300 dark:bg-gray-500"
              }`}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default SkillsSection;
