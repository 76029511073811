import React, { useEffect, useRef, useState } from "react";

interface AnimatedTextProps {
  text: string;
  className?: string;
  tag?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "span";
  staggerDelay?: number;
  effect?: "fade" | "bounce" | "slide" | "glitch" | "typewriter";
  color?: string;
  isGradient?: boolean;
  isNeon?: boolean;
  once?: boolean;
}

const AnimatedText: React.FC<AnimatedTextProps> = ({
  text,
  className = "",
  tag: Tag = "h1",
  staggerDelay = 0.05,
  effect = "fade",
  color = "",
  isGradient = false,
  isNeon = false,
  once = true,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const textRef = useRef<
    HTMLHeadingElement | HTMLParagraphElement | HTMLSpanElement
  >(null);

  const getEffectClass = (index: number) => {
    const delay = `${index * staggerDelay}s`;

    switch (effect) {
      case "bounce":
        return `animate-letter animate-letter-bounce`;
      case "slide":
        return `animate-letter animate-letter-slide`;
      case "glitch":
        return `animate-letter animate-letter-glitch`;
      case "typewriter":
        return `animate-letter animate-letter-typewriter`;
      case "fade":
      default:
        return `animate-letter animate-letter-fade`;
    }
  };

  const getColorClass = () => {
    if (isGradient) return "gradient-text";
    if (isNeon) return "neon-text";
    if (color) return color;
    return "";
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        if (entry.isIntersecting) {
          setIsVisible(true);
          if (once) {
            observer.disconnect();
          }
        } else if (!once) {
          setIsVisible(false);
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    if (textRef.current) {
      observer.observe(textRef.current);
    }

    return () => {
      if (textRef.current) {
        observer.unobserve(textRef.current);
      }
    };
  }, [once]);

  return (
    <Tag
      ref={textRef as React.RefObject<any>}
      className={`animate-letters ${
        isVisible ? "visible" : ""
      } ${getColorClass()} ${className}`}
    >
      {text.split("").map((char, index) => (
        <span
          key={`${index}-${char}`}
          className={getEffectClass(index)}
          style={{
            transitionDelay: `${index * staggerDelay}s`,
            display: char === " " ? "inline" : "inline-block",
            animationDelay: `${index * staggerDelay}s`,
          }}
        >
          {char === " " ? "\u00A0" : char}
        </span>
      ))}
    </Tag>
  );
};

export default AnimatedText;
