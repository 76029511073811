import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Header from "./components/Header";
import Home from "./components/Main";
import ResumePage from "./components/ResumePage";
import ThemeCustomizer from "./components/ThemeCustomizer";
import Footer from "./components/Footer";
import { ScrollToTop, BackToTop } from "./components/ScrollToTop";
import ScrollProgress from "./components/ScrollProgress";
import toast, { Toaster } from "react-hot-toast";
import AnimatedText from "./components/AnimatedText";

const App: React.FC = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingProgress, setLoadingProgress] = useState(0);

  // Handle dark mode preference
  useEffect(() => {
    // Check saved preference
    const savedDarkMode = localStorage.getItem("dark-mode");
    if (savedDarkMode === "true") {
      setIsDarkMode(true);
      document.documentElement.classList.add("dark");
    } else if (savedDarkMode === "false") {
      setIsDarkMode(false);
      document.documentElement.classList.remove("dark");
    } else {
      // Check system preference if no saved preference
      const prefersDark = window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
      setIsDarkMode(prefersDark);
      if (prefersDark) {
        document.documentElement.classList.add("dark");
      }
    }
  }, []);

  // Update dark mode
  const toggleDarkMode = () => {
    setIsDarkMode((prev) => {
      const newMode = !prev;
      localStorage.setItem("dark-mode", newMode.toString());

      if (newMode) {
        document.documentElement.classList.add("dark");
        toast("Dark mode enabled", {
          icon: "🌙",
          style: {
            borderRadius: "10px",
            background: "#1e293b",
            color: "#fff",
          },
        });
      } else {
        document.documentElement.classList.remove("dark");
        toast("Light mode enabled", {
          icon: "☀️",
          style: {
            borderRadius: "10px",
            background: "#ffffff",
            color: "#000",
          },
        });
      }

      return newMode;
    });
  };

  // Simulate loading state with progress
  useEffect(() => {
    let loadingTimer: NodeJS.Timeout;
    let progressInterval: NodeJS.Timeout;

    const simulateLoading = () => {
      progressInterval = setInterval(() => {
        setLoadingProgress((prev) => {
          const next = prev + Math.random() * 15;
          return next > 100 ? 100 : next;
        });
      }, 150);

      loadingTimer = setTimeout(() => {
        clearInterval(progressInterval);
        setLoadingProgress(100);

        setTimeout(() => {
          setIsLoading(false);
        }, 500);
      }, 1500);
    };

    simulateLoading();

    return () => {
      clearTimeout(loadingTimer);
      clearInterval(progressInterval);
    };
  }, []);

  // If still loading, show animated loading screen
  if (isLoading) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-white dark:bg-gray-900 z-50">
        <div className="text-center max-w-md px-6">
          <div className="relative w-16 h-16 mx-auto mb-8">
            <div className="w-16 h-16 border-4 border-gray-200 dark:border-gray-700 border-t-my-blue rounded-full animate-spin"></div>
            <div
              className="absolute top-0 left-0 w-full text-center text-xs font-bold text-my-blue mt-6"
              style={{
                opacity: loadingProgress > 30 ? 1 : 0,
                transition: "opacity 0.3s",
              }}
            >
              {Math.round(loadingProgress)}%
            </div>
          </div>

          <AnimatedText
            text="Loading Owen's Portfolio"
            tag="h2"
            className="text-2xl font-semibold text-gray-800 dark:text-white mb-2"
            effect="typewriter"
          />

          <p className="text-gray-600 dark:text-gray-300 mt-2 opacity-70">
            Preparing an awesome experience for you...
          </p>

          <div className="w-full bg-gray-200 dark:bg-gray-700 rounded-full h-1.5 mt-6">
            <div
              className="bg-gradient-primary h-1.5 rounded-full transition-all duration-300"
              style={{ width: `${loadingProgress}%` }}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <Router>
      <ScrollToTop />

      {/* Page wrapper */}
      <div className="min-h-screen flex flex-col items-center bg-gray-50 dark:bg-gray-900 transition-colors duration-300">
        {/* Header */}
        <Header isDarkMode={isDarkMode} toggleDarkMode={toggleDarkMode} />

        {/* Scroll progress bar */}
        <ScrollProgress isGradient={true} height={4} />

        {/* Main content area */}
        <main className="w-full flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/resume" element={<ResumePage />} />
          </Routes>
        </main>

        {/* Footer */}
        <Footer />

        {/* Theme Customizer */}
        <ThemeCustomizer
          isDarkMode={isDarkMode}
          toggleDarkMode={toggleDarkMode}
        />

        {/* Back to top button */}
        <BackToTop />

        {/* Toast notifications */}
        <Toaster
          position="bottom-center"
          toastOptions={{
            duration: 3000,
            style: {
              borderRadius: "10px",
              background: isDarkMode ? "#1e293b" : "#ffffff",
              color: isDarkMode ? "#ffffff" : "#1a202c",
              boxShadow: isDarkMode
                ? "0 10px 15px -3px rgba(0, 0, 0, 0.3), 0 4px 6px -2px rgba(0, 0, 0, 0.2)"
                : "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
            },
          }}
        />
      </div>
    </Router>
  );
};

export default App;
