import React, { useEffect } from "react";

const AboutMeSection: React.FC = () => {
  // Function to check if element is in viewport for animation
  const useInViewAnimation = () => {
    useEffect(() => {
      const observerOptions = {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      };

      const handleIntersect = (
        entries: IntersectionObserverEntry[],
        observer: IntersectionObserver
      ) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate-in");
            observer.unobserve(entry.target);
          }
        });
      };

      const observer = new IntersectionObserver(
        handleIntersect,
        observerOptions
      );

      document.querySelectorAll(".animate-on-scroll").forEach((el) => {
        observer.observe(el);
      });

      return () => {
        observer.disconnect();
      };
    }, []);
  };

  useInViewAnimation();

  return (
    <section
      id="about"
      className="flex justify-center items-center dark:bg-gray-800 text-black dark:text-white w-full py-16 md:py-20 px-4"
    >
      <div className="w-full max-w-7xl bg-gradient-to-br from-gray-50 to-gray-100 dark:from-gray-700 dark:to-gray-800 text-center rounded-3xl shadow-2xl dark:shadow-2xl overflow-hidden">
        <div className="p-6 md:p-10 lg:p-12">
          <h2 className="text-4xl md:text-5xl font-bold mb-8 text-gray-800 dark:text-white animate-on-scroll opacity-0 transition-all duration-700 transform translate-y-10 animate-in:opacity-100 animate-in:translate-y-0">
            about me
          </h2>

          <div className="grid md:grid-cols-2 gap-8 md:gap-12">
            {/* Left Column */}
            <div className="animate-on-scroll opacity-0 transition-all duration-700 delay-100 transform translate-y-10 animate-in:opacity-100 animate-in:translate-y-0">
              <p className="text-lg leading-relaxed mb-6 text-left">
                Hi! I'm Owen, a Junior at Indiana University Bloomington
                studying Computer Science (concentrated in artificial
                intelligence) with a minor in data science and web development.
                I'm passionate about software engineering, web development,
                graphic design, artificial intelligence, and machine learning. I
                have experience with many front-end and back-end technologies,
                and I'm always looking to learn more!
              </p>
              <p className="text-lg leading-relaxed mb-6 text-left">
                I currently serve on the core team of Google Developer Student
                Clubs @ IU as event coordinator. From planning and leading
                technical workshops to organizing meetings, I love to help
                others learn and grow in the tech field. Last year, we planned
                and orchestrated the largest student-led Hackathon in the
                history of Indiana University, collaborating with 5+ student
                organizations and 3+ companies with over 100 students and 25
                teams competing.
              </p>
            </div>

            {/* Right Column */}
            <div className="animate-on-scroll opacity-0 transition-all duration-700 delay-200 transform translate-y-10 animate-in:opacity-100 animate-in:translate-y-0">
              <p className="text-lg leading-relaxed mb-6 text-left">
                Although I'm a computer science student, I also have a passion
                for lots of other things! I love to cook, play guitar, go on
                hikes, and play soccer. I'm a huge fan of the Chicago Bears and
                Manchester United. I love all types of music, and I am always
                looking to discover new artists and genres. I am even in the
                process of gaining a Certificate of Rock Music History through
                Indiana University's Jacobs School of Music. Lastly, I love to
                travel and explore new places, and I hope to visit every
                continent in my lifetime.
              </p>
              <p className="text-lg leading-relaxed text-left">
                Ever since a young age I have been fascinated with technology
                and how it works. I love to create and build things, and I'm
                always looking for new projects to work on. I also believe my
                creativity combined with my critical thinking and problem
                solving will make me an excellent full stack developer and
                employee.{" "}
                <span className="bg-gradient-to-r from-blue-500 to-purple-500 text-transparent bg-clip-text font-semibold">
                  I'm currently seeking internships for Summer 2025, so feel
                  free to reach out!
                </span>
              </p>

            </div>
          </div>

          {/* Decorative Elements */}
          <div className="relative h-2 w-24 mx-auto mt-12 bg-gradient-to-r from-blue-400 to-purple-500 rounded-full"></div>
        </div>
      </div>
    </section>
  );
};

export default AboutMeSection;
