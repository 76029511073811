import React, { useState, useEffect, useRef } from "react";
import { CaretLeft, X, GithubLogo, LinkSimple } from "@phosphor-icons/react";

// Project type definition
type Project = {
  id: string;
  name: string;
  img: string;
  description: string;
  longDescription?: string;
  technologies?: string[];
  link: string;
  sourceCode?: string;
  featured?: boolean;
};

// Enhanced project data
const projects: Project[] = [
  {
    id: "march-madness",
    name: "March Madness Predictor",
    img: "https://upload.wikimedia.org/wikipedia/commons/5/5a/Defense.gov_photo_essay_120112-D-VO565-015.jpg",
    description:
      "Machine learning model to predict NCAA basketball tournament outcomes",
    longDescription:
      "Developed a machine learning model using historical NCAA basketball data to predict March Madness tournament outcomes. The model analyzes team statistics, historical performance, and matchup data to generate bracket predictions with impressive accuracy.",
    technologies: [
      "Python",
      "Scikit-learn",
      "Pandas",
      "Jupyter Notebook",
      "Kaggle API",
    ],
    link: "https://www.kaggle.com/code/owenkleinmaier/march-madness-predictor",
    sourceCode:
      "https://www.kaggle.com/code/owenkleinmaier/march-madness-predictor",
    featured: true,
  },
  {
    id: "easy-bold-reader",
    name: "Easy Bold Reader",
    img: "https://github.com/owenkleinmaier/EasyBoldReader/blob/main/128-icon.png?raw=true",
    description:
      "Chrome extension that enhances readability by emphasizing key text",
    longDescription:
      "Created a Chrome extension that improves reading comprehension by automatically identifying and bolding important words and phrases in articles and web content. Helps users with ADHD, dyslexia, and other reading challenges focus on essential content.",
    technologies: ["JavaScript", "HTML", "CSS", "Chrome Extension API"],
    link: "https://chromewebstore.google.com/detail/easy-bold-reader/oakpjlnlggmfdmdllhpdnbekgikjjaaj?hl=en",
    sourceCode: "https://github.com/owenkleinmaier/EasyBoldReader",
    featured: true,
  },
  {
    id: "age-calculator",
    name: "Age Calculator",
    img: "https://raw.githubusercontent.com/owenkleinmaier/age-calculator/597b575b0c4692f0448469766c1464f4da3ef01b/stacked-waves-haikei.svg",
    description:
      "Interactive web app that calculates precise age based on birth date",
    longDescription:
      "Developed a responsive web application that calculates a user's exact age in years, months, days, hours, and minutes. Features a clean, intuitive interface and animations to enhance user experience.",
    technologies: ["HTML", "CSS", "JavaScript", "Responsive Design"],
    link: "https://agecalculat0r.netlify.app/",
    sourceCode: "https://github.com/owenkleinmaier/age-calculator",
  },
  {
    id: "wordle-promposal",
    name: "Wordle Promposal",
    img: "https://www.thetimes.com/imageserver/image/%2Fmethode%2Ftimes%2Fprod%2Fweb%2Fbin%2F263989ba-730a-11ec-89e9-22d3d9c31ba2.jpg?crop=1258%2C838%2C121%2C81",
    description: "Custom Wordle clone that delivered a unique prom invitation",
    longDescription:
      "This was my very first project ever! Created a personalized version of the popular Wordle game as a creative way to ask my girlfriend to prom. Modified the original game mechanics to ensure the final word would be 'PROM', revealing the invitation upon completion.",
    technologies: ["React", "JavaScript", "CSS", "Local Storage API"],
    link: "https://github.com/owenkleinmaier/wordle-clone-promposal",
    sourceCode: "https://github.com/owenkleinmaier/wordle-clone-promposal",
  },
  {
    id: "personal-website",
    name: "My Personal Website",
    img: "https://owenkleinmaier.com/static/media/desk.92791fecd9e8ce4f49c055b4c7d60dd0.svg",
    description:
      "Portfolio website showcasing skills, projects, and experiences",
    longDescription:
      "Designed and developed this responsive personal portfolio website to showcase my projects, skills, and professional journey. Features include dark mode, responsive design, and interactive elements to create an engaging user experience.",
    technologies: ["React", "TypeScript", "Tailwind CSS", "Responsive Design"],
    link: "https://www.owenkleinmaier.com",
    sourceCode: "https://github.com/owenkleinmaier/www.owenkleinmaier.com",
    featured: true,
  },
];

const ProjectsSection: React.FC = () => {
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [animateProjects, setAnimateProjects] = useState(true);
  const modalRef = useRef<HTMLDivElement>(null);

  // Handle selecting a project for the modal
  const handleProjectClick = (project: Project) => {
    setSelectedProject(project);
    setIsModalOpen(true);
    document.body.style.overflow = "hidden"; // Prevent scrolling when modal is open
  };

  // Close the modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
    document.body.style.overflow = ""; // Re-enable scrolling
  };

  // Close modal when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        handleCloseModal();
      }
    };

    if (isModalOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isModalOpen]);

  // Close modal with escape key
  useEffect(() => {
    const handleEscKey = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        handleCloseModal();
      }
    };

    if (isModalOpen) {
      window.addEventListener("keydown", handleEscKey);
    }

    return () => {
      window.removeEventListener("keydown", handleEscKey);
    };
  }, [isModalOpen]);

  // Animation on scroll
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate-fade-in");
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    document.querySelectorAll(".project-animate").forEach((el) => {
      observer.observe(el);
    });

    return () => observer.disconnect();
  }, []);

  // Get featured projects
  const featuredProjects = projects.filter((project) => project.featured);

  return (
    <section
      id="projects"
      className="py-16 flex justify-center items-center dark:bg-gray-800 text-black dark:text-white w-full px-4 md:px-6"
    >
      <div className="w-full max-w-7xl p-4 md:p-8 bg-gradient-to-br from-gray-50 to-gray-100 dark:from-gray-700 dark:to-gray-800 text-center rounded-3xl shadow-2xl dark:shadow-2xl">
        <h1 className="text-4xl md:text-5xl font-bold mb-6 project-animate opacity-0 transition-opacity duration-700">
          my projects
        </h1>

        {/* Featured Projects Section */}
        {featuredProjects.length > 0 && (
          <div className="mb-12 project-animate opacity-0 transition-opacity duration-700 delay-100">
            <h2 className="text-2xl font-semibold mb-6 text-left">
              Featured Projects
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {featuredProjects.map((project, index) => (
                <ProjectCard
                  key={project.id}
                  project={project}
                  onClick={() => handleProjectClick(project)}
                  delay={index * 100}
                  featured={true}
                />
              ))}
            </div>
          </div>
        )}

        {/* All Projects Grid */}
        <div className="project-animate opacity-0 transition-opacity duration-700 delay-200">
          <h2 className="text-2xl font-semibold mb-6 text-left">
            All Projects
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6">
            {projects.map((project, index) => (
              <ProjectCard
                key={project.id}
                project={project}
                onClick={() => handleProjectClick(project)}
                delay={index * 100}
              />
            ))}
          </div>
        </div>

        {/* Project Detail Modal */}
        {isModalOpen && selectedProject && (
          <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black bg-opacity-70 backdrop-blur-sm transition-opacity duration-300">
            <div
              ref={modalRef}
              className="bg-white dark:bg-gray-800 rounded-2xl shadow-2xl w-full max-w-3xl max-h-[90vh] overflow-y-auto transform transition-all duration-300 scale-100"
            >
              {/* Modal Close Button */}
              <button
                onClick={handleCloseModal}
                className="absolute top-4 right-4 z-20 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-white transition-colors"
                aria-label="Close modal"
              >
                <X size={24} weight="bold" />
              </button>

              {/* Project Image */}
              <div className="relative w-full h-48 md:h-64 overflow-hidden">
                <div
                  className="absolute inset-0 bg-cover bg-center"
                  style={{ backgroundImage: `url(${selectedProject.img})` }}
                ></div>
                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-white dark:to-gray-800"></div>
              </div>

              {/* Project Details */}
              <div className="p-6">
                <h2 className="text-3xl font-bold mb-4">
                  {selectedProject.name}
                </h2>

                <p className="text-lg mb-6 text-gray-700 dark:text-gray-300">
                  {selectedProject.longDescription ||
                    selectedProject.description}
                </p>

                {/* Technologies Used */}
                {selectedProject.technologies && (
                  <div className="mb-6">
                    <h3 className="text-xl font-semibold mb-2">
                      Technologies Used
                    </h3>
                    <div className="flex flex-wrap gap-2">
                      {selectedProject.technologies.map((tech) => (
                        <span
                          key={tech}
                          className="px-3 py-1 bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200 rounded-full text-sm"
                        >
                          {tech}
                        </span>
                      ))}
                    </div>
                  </div>
                )}

                {/* Action Buttons */}
                <div className="flex flex-col sm:flex-row gap-4 mt-4">
                  <a
                    href={selectedProject.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center justify-center gap-2 px-6 py-2 bg-my-blue text-white font-medium rounded-md shadow-md hover:bg-blue-600 transition-colors"
                  >
                    <LinkSimple size={20} />
                    View Project
                  </a>
                  {selectedProject.sourceCode && (
                    <a
                      href={selectedProject.sourceCode}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center justify-center gap-2 px-6 py-2 bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-white font-medium rounded-md shadow-md hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors"
                    >
                      <GithubLogo size={20} />
                      Source Code
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

// Project Card Component
const ProjectCard: React.FC<{
  project: Project;
  onClick: () => void;
  delay?: number;
  featured?: boolean;
}> = ({ project, onClick, delay = 0, featured = false }) => {
  return (
    <div
      className={`group relative overflow-hidden rounded-xl shadow-md cursor-pointer transform transition-all duration-500 hover:shadow-xl hover:scale-[1.02] ${
        featured ? "aspect-video" : "aspect-square"
      }`}
      onClick={onClick}
      style={{ animationDelay: `${delay}ms` }}
    >
      {/* Background Image with Overlay */}
      <div
        className="absolute inset-0 bg-cover bg-center transition-transform duration-700 group-hover:scale-110"
        style={{ backgroundImage: `url(${project.img})` }}
      ></div>

      {/* Gradient Overlay */}
      <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent opacity-80"></div>

      {/* Content */}
      <div className="absolute inset-0 p-4 md:p-6 flex flex-col justify-end transition-transform duration-300">
        <h3 className="text-xl md:text-2xl font-bold text-white mb-2">
          {project.name}
        </h3>
        <p className="text-sm md:text-base text-gray-200 mb-4 line-clamp-2">
          {project.description}
        </p>

        {/* Visible technologies (if featured) */}
        {featured && project.technologies && (
          <div className="hidden md:flex flex-wrap gap-1 mb-3">
            {project.technologies.slice(0, 3).map((tech) => (
              <span
                key={tech}
                className="px-2 py-1 bg-white bg-opacity-20 backdrop-blur-sm text-white rounded-full text-xs"
              >
                {tech}
              </span>
            ))}
            {project.technologies.length > 3 && (
              <span className="px-2 py-1 bg-white bg-opacity-20 backdrop-blur-sm text-white rounded-full text-xs">
                +{project.technologies.length - 3} more
              </span>
            )}
          </div>
        )}

        {/* View Details Button */}
        <button className="opacity-0 group-hover:opacity-100 transition-opacity duration-300 px-4 py-2 bg-blue-600 text-white text-sm font-medium rounded-md self-start hover:bg-blue-700">
          View Details
        </button>
      </div>
    </div>
  );
};

export default ProjectsSection;
