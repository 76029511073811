import React from "react";
import { useEffect, useState, useRef } from "react";
import {
  LinkedinLogo,
  GithubLogo,
  EnvelopeSimple,
  CaretDown,
} from "@phosphor-icons/react";
import { ReactTyped } from "react-typed";
import DeskSVG from "../assets/desk.svg";

// Import newly created sections
import AboutMeSection from "./AboutMeSection";
import SkillsSection from "./SkillsSection";
import ProjectsSection from "./ProjectsSection";

const Main: React.FC = () => {
  const [showArrow, setShowArrow] = useState(true);
  const mainRef = useRef<HTMLDivElement>(null);
  const [offset, setOffset] = useState(0);

  // Parallax effect
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setShowArrow(false);
      }

      // Parallax effect for main section
      setOffset(window.scrollY * 0.3);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    // Delay animations to prevent flash
    const timer = setTimeout(() => {
      document.querySelectorAll(".animate-slidein").forEach((el) => {
        el.classList.remove("opacity-0");
      });
    }, 250);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {/* Main Section with Parallax Effect */}
      <section
        ref={mainRef}
        className="relative min-h-screen flex flex-col justify-center items-center overflow-hidden dark:bg-gray-800 w-full"
        style={{
          paddingTop: "6rem",
          paddingBottom: "6rem",
        }}
      >
        <div
          className="absolute inset-0 z-0 bg-gradient-to-b from-blue-50 to-white dark:from-gray-900 dark:to-gray-800"
          style={{ transform: `translateY(${offset}px)` }}
        ></div>

        <div className="container mx-auto px-6 md:px-12 z-10 flex flex-col lg:flex-row items-center">
          {/* Content Container */}
          <div className="w-full lg:w-1/2 text-left mb-12 lg:mb-0">
            {/* Title */}
            <h1 className="text-6xl md:text-8xl font-bold text-black dark:text-white leading-tight">
              hi, my name is <br />
              <span className="text-my-blue text-7xl md:text-9xl">owen.</span>
            </h1>

            {/* Typing Animation */}
            <p className="text-3xl md:text-4xl text-black dark:text-white mt-4">
              i am{" "}
              <ReactTyped
                strings={[
                  "a computer science student. _",
                  "an aspiring full-stack developer. _",
                ]}
                typeSpeed={35}
                backSpeed={35}
                loop
                className="text-my-blue"
              />
            </p>

            {/* Links and Icons */}
            <div className="flex flex-wrap items-center gap-4 mt-8">
              <a
                href="/resume"
                className="px-5 py-2 bg-my-blue text-white font-medium rounded-md shadow-md hover:bg-blue-600 transition-all hover:shadow-lg hover:scale-105 text-xl md:text-2xl"
              >
                my resume
              </a>
              <div className="flex space-x-4 mt-2 md:mt-0">
                <a
                  href="https://www.linkedin.com/in/owen-kleinmaier"
                  aria-label="LinkedIn"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="transform hover:scale-110 transition-transform duration-300"
                >
                  <LinkedinLogo
                    size={36}
                    weight="fill"
                    className="text-blue-600 hover:text-blue-800 transition-colors dark:text-blue-400 dark:hover:text-blue-600"
                  />
                </a>
                <a
                  href="https://github.com/owenkleinmaier"
                  aria-label="GitHub"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="transform hover:scale-110 transition-transform duration-300"
                >
                  <GithubLogo
                    size={36}
                    weight="fill"
                    className="text-gray-700 hover:text-black transition-colors dark:text-gray-300 dark:hover:text-white"
                  />
                </a>
                <a
                  href="mailto:owenkleinmaier@gmail.com"
                  aria-label="Gmail"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="transform hover:scale-110 transition-transform duration-300"
                >
                  <EnvelopeSimple
                    size={36}
                    weight="fill"
                    className="text-red-500 hover:text-red-700 transition-colors dark:text-red-400 dark:hover:text-red-600"
                  />
                </a>
              </div>
            </div>
          </div>

          

          {/* SVG Illustration with Animation */}
          <div className="w-full lg:w-1/2 flex justify-center items-center">
            <div className="relative w-full max-w-md">
              <img
                src={DeskSVG}
                alt="Desk"
                className="w-full h-auto mx-auto drop-shadow-xl transform hover:scale-105 transition-transform duration-700"
              />
              <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-br from-blue-200 to-purple-200 dark:from-blue-500 dark:to-purple-500 rounded-full filter blur-3xl opacity-20 animate-pulse"></div>
            </div>
          </div>
        </div>

        {/* Arrow to beckon scrolling */}
        {showArrow && (
          <div className="absolute bottom-10 left-1/2 transform -translate-x-1/2 animate-bounce text-gray-800 dark:text-white">
            <CaretDown size={36} />
          </div>
        )}
      </section>

      {/* About Me Section */}
      <AboutMeSection />

      {/* Skills Section */}
      <SkillsSection />

      {/* Projects Section */}
      <ProjectsSection />
    </>
  );
};

export default Main;
