import React, { useState, useEffect } from "react";
import {
  PaintBrush,
  X,
  Check,
  Sparkle,
  Moon,
  Sun,
  ArrowsClockwise,
} from "@phosphor-icons/react";
import toast from "react-hot-toast";

// Define expanded theme options with more properties
interface ThemeOption {
  name: string;
  primary: string;
  secondary: string;
  accent?: string;
  gradient: string;
  buttonStyle?: string;
  cardStyle?: string;
  borderStyle?: string;
}

// Enhanced theme options with more variety and properties
const themeOptions: ThemeOption[] = [
  {
    name: "Default Blue",
    primary: "#3b82f6",
    secondary: "#a855f7",
    accent: "#0ea5e9",
    gradient: "linear-gradient(135deg, #3b82f6 0%, #a855f7 100%)",
    buttonStyle: "rounded-md",
    cardStyle: "rounded-xl",
    borderStyle: "border-solid",
  },
  {
    name: "Forest Green",
    primary: "#10b981",
    secondary: "#34d399",
    accent: "#059669",
    gradient: "linear-gradient(135deg, #10b981 0%, #34d399 100%)",
    buttonStyle: "rounded-lg",
    cardStyle: "rounded-xl",
    borderStyle: "border-solid",
  },
  {
    name: "Ruby Red",
    primary: "#ef4444",
    secondary: "#f87171",
    accent: "#b91c1c",
    gradient: "linear-gradient(135deg, #ef4444 0%, #f87171 100%)",
    buttonStyle: "rounded-md",
    cardStyle: "rounded-2xl",
    borderStyle: "border-solid",
  },
  {
    name: "Royal Purple",
    primary: "#8b5cf6",
    secondary: "#a78bfa",
    accent: "#7c3aed",
    gradient: "linear-gradient(135deg, #8b5cf6 0%, #a78bfa 100%)",
    buttonStyle: "rounded-full",
    cardStyle: "rounded-3xl",
    borderStyle: "border-solid",
  },
  {
    name: "Ocean Teal",
    primary: "#0d9488",
    secondary: "#2dd4bf",
    accent: "#0f766e",
    gradient: "linear-gradient(135deg, #0d9488 0%, #2dd4bf 100%)",
    buttonStyle: "rounded-lg",
    cardStyle: "rounded-xl",
    borderStyle: "border-solid",
  },
  {
    name: "Sunset Orange",
    primary: "#f97316",
    secondary: "#fb923c",
    accent: "#ea580c",
    gradient: "linear-gradient(135deg, #f97316 0%, #fb923c 100%)",
    buttonStyle: "rounded-md",
    cardStyle: "rounded-2xl",
    borderStyle: "border-solid",
  },
  {
    name: "Neon Cyberpunk",
    primary: "#f0abfc",
    secondary: "#2563eb",
    accent: "#f472b6",
    gradient: "linear-gradient(135deg, #f0abfc 0%, #2563eb 100%)",
    buttonStyle: "rounded-none",
    cardStyle: "rounded-sm",
    borderStyle: "border-dashed",
  },
  {
    name: "Retro Wave",
    primary: "#ec4899",
    secondary: "#8b5cf6",
    accent: "#3b82f6",
    gradient: "linear-gradient(135deg, #ec4899 0%, #8b5cf6 50%, #3b82f6 100%)",
    buttonStyle: "rounded-md",
    cardStyle: "rounded-xl",
    borderStyle: "border-solid",
  },
  {
    name: "Midnight",
    primary: "#312e81",
    secondary: "#1e40af",
    accent: "#1d4ed8",
    gradient: "linear-gradient(135deg, #312e81 0%, #1e40af 100%)",
    buttonStyle: "rounded-full",
    cardStyle: "rounded-2xl",
    borderStyle: "border-solid",
  },
];

// Define gradient styles for background options
const gradientStyles = [
  {
    name: "Subtle",
    value:
      "linear-gradient(135deg, var(--color-primary-light) 0%, var(--color-secondary-light) 100%)",
  },
  {
    name: "Radial",
    value:
      "radial-gradient(circle, var(--color-primary-light) 0%, var(--color-secondary-light) 100%)",
  },
  {
    name: "Angular",
    value:
      "conic-gradient(from 45deg, var(--color-primary-light), var(--color-secondary-light), var(--color-primary-light))",
  },
  {
    name: "Mesh",
    value:
      "linear-gradient(45deg, var(--color-primary-light) 25%, transparent 25%), linear-gradient(-45deg, var(--color-primary-light) 25%, transparent 25%), linear-gradient(45deg, transparent 75%, var(--color-primary-light) 75%), linear-gradient(-45deg, transparent 75%, var(--color-primary-light) 75%)",
  },
];

// Define UI modes (normal, playful, minimalist, etc.)
const uiModes = [
  { name: "Standard", value: "standard" },
  { name: "Playful", value: "playful" },
  { name: "Minimal", value: "minimal" },
  { name: "Glassmorphism", value: "glass" },
];

// Define prop interface
interface ThemeCustomizerProps {
  isDarkMode: boolean;
  toggleDarkMode: () => void;
}

const ThemeCustomizer: React.FC<ThemeCustomizerProps> = ({
  isDarkMode,
  toggleDarkMode,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("colors");
  const [selectedTheme, setSelectedTheme] = useState(0);
  const [selectedGradient, setSelectedGradient] = useState(0);
  const [selectedUiMode, setSelectedUiMode] = useState(0);
  const [animationLevel, setAnimationLevel] = useState(2); // 0=none, 1=subtle, 2=moderate, 3=high
  const [showSparkles, setShowSparkles] = useState(false);

  // Set theme properties in CSS variables
  useEffect(() => {
    if (selectedTheme !== null) {
      const theme = themeOptions[selectedTheme];

      // Set base colors
      document.documentElement.style.setProperty(
        "--color-primary",
        theme.primary
      );
      document.documentElement.style.setProperty(
        "--color-secondary",
        theme.secondary
      );
      document.documentElement.style.setProperty(
        "--color-accent",
        theme.accent || theme.primary
      );

      // Set derived colors (lighter/darker versions)
      document.documentElement.style.setProperty(
        "--color-primary-light",
        `${theme.primary}33`
      ); // 20% opacity
      document.documentElement.style.setProperty(
        "--color-secondary-light",
        `${theme.secondary}33`
      );

      // Set gradient
      document.documentElement.style.setProperty(
        "--gradient-primary",
        theme.gradient
      );

      // Set UI styles
      document.documentElement.style.setProperty(
        "--button-radius",
        theme.buttonStyle || "rounded-md"
      );
      document.documentElement.style.setProperty(
        "--card-radius",
        theme.cardStyle || "rounded-xl"
      );
      document.documentElement.style.setProperty(
        "--border-style",
        theme.borderStyle || "border-solid"
      );

      // Set selected gradient
      document.documentElement.style.setProperty(
        "--gradient-background",
        gradientStyles[selectedGradient].value
      );

      // Set animation level
      document.documentElement.style.setProperty(
        "--animation-speed",
        ["0s", "0.7s", "0.5s", "0.3s"][animationLevel]
      );
      document.documentElement.style.setProperty(
        "--animation-scale",
        ["1", "1.02", "1.05", "1.1"][animationLevel]
      );

      // Set UI mode classes
      document.body.classList.remove(
        "ui-standard",
        "ui-playful",
        "ui-minimal",
        "ui-glass"
      );
      document.body.classList.add(`ui-${uiModes[selectedUiMode].value}`);

      // Preserve sparkle setting
      if (showSparkles) {
        document.body.classList.add("sparkle-elements");
      } else {
        document.body.classList.remove("sparkle-elements");
      }
    }
  }, [
    selectedTheme,
    selectedGradient,
    selectedUiMode,
    animationLevel,
    showSparkles,
  ]);

  // Load saved themes from localStorage on initial render
  useEffect(() => {
    const savedTheme = localStorage.getItem("selected-theme");
    const savedGradient = localStorage.getItem("selected-gradient");
    const savedUiMode = localStorage.getItem("selected-ui-mode");
    const savedAnimationLevel = localStorage.getItem("animation-level");
    const savedSparkles = localStorage.getItem("show-sparkles");

    if (savedTheme !== null) setSelectedTheme(parseInt(savedTheme));
    if (savedGradient !== null) setSelectedGradient(parseInt(savedGradient));
    if (savedUiMode !== null) setSelectedUiMode(parseInt(savedUiMode));
    if (savedAnimationLevel !== null)
      setAnimationLevel(parseInt(savedAnimationLevel));
    if (savedSparkles !== null) setShowSparkles(savedSparkles === "true");
  }, []);

  // Save theme settings to localStorage when changed
  useEffect(() => {
    localStorage.setItem("selected-theme", selectedTheme.toString());
    localStorage.setItem("selected-gradient", selectedGradient.toString());
    localStorage.setItem("selected-ui-mode", selectedUiMode.toString());
    localStorage.setItem("animation-level", animationLevel.toString());
    localStorage.setItem("show-sparkles", showSparkles.toString());
  }, [
    selectedTheme,
    selectedGradient,
    selectedUiMode,
    animationLevel,
    showSparkles,
  ]);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const applyTheme = (index: number) => {
    setSelectedTheme(index);
    toast.success(`${themeOptions[index].name} theme applied!`, {
      icon: "🎨",
      style: {
        borderRadius: "10px",
        background: isDarkMode ? "#1e293b" : "#ffffff",
        color: isDarkMode ? "#ffffff" : "#1e293b",
      },
    });
  };

  const applyGradient = (index: number) => {
    setSelectedGradient(index);
    toast(`Gradient style updated`, {
      icon: "🌈",
      style: {
        borderRadius: "10px",
        background: isDarkMode ? "#1e293b" : "#ffffff",
        color: isDarkMode ? "#ffffff" : "#1e293b",
      },
    });
  };

  const applyUiMode = (index: number) => {
    setSelectedUiMode(index);
    toast(`UI style: ${uiModes[index].name}`, {
      icon: "✨",
      style: {
        borderRadius: "10px",
        background: isDarkMode ? "#1e293b" : "#ffffff",
        color: isDarkMode ? "#ffffff" : "#1e293b",
      },
    });
  };

  const toggleSparkles = () => {
    setShowSparkles(!showSparkles);
    toast(`${!showSparkles ? "Added" : "Removed"} sparkle effect`, {
      icon: !showSparkles ? "✨" : "❌",
      style: {
        borderRadius: "10px",
        background: isDarkMode ? "#1e293b" : "#ffffff",
        color: isDarkMode ? "#ffffff" : "#1e293b",
      },
    });
  };

  // Generate random theme
  const randomizeTheme = () => {
    const randomTheme = Math.floor(Math.random() * themeOptions.length);
    const randomGradient = Math.floor(Math.random() * gradientStyles.length);
    const randomUiMode = Math.floor(Math.random() * uiModes.length);

    setSelectedTheme(randomTheme);
    setSelectedGradient(randomGradient);
    setSelectedUiMode(randomUiMode);

    toast.success("Random theme applied!", {
      icon: "🎲",
      style: {
        borderRadius: "10px",
        background: isDarkMode ? "#1e293b" : "#ffffff",
        color: isDarkMode ? "#ffffff" : "#1e293b",
      },
    });
  };

  return (
    <>
      {/* Floating toggle button */}
      <button
        onClick={toggleOpen}
        className="fixed bottom-6 right-6 z-40 p-3 rounded-full bg-gradient-to-r from-primary to-secondary text-white shadow-lg transform transition-transform hover:scale-110"
        aria-label="Customize theme"
        style={{
          background: themeOptions[selectedTheme].gradient,
        }}
      >
        <PaintBrush size={24} weight="bold" />
      </button>

      {/* Theme customizer panel */}
      <div
        className={`fixed bottom-20 right-6 z-30 p-4 w-80 rounded-xl bg-white dark:bg-gray-800 shadow-xl transform transition-all duration-300 ${
          isOpen
            ? "translate-y-0 opacity-100"
            : "translate-y-24 opacity-0 pointer-events-none"
        }`}
      >
        <div className="flex justify-between items-center mb-4">
          <h3 className="font-bold text-lg text-gray-800 dark:text-white">
            Customize Your Experience
          </h3>
          <button
            onClick={toggleOpen}
            className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-white"
          >
            <X size={20} />
          </button>
        </div>

        {/* Tab navigation */}
        <div className="flex border-b border-gray-200 dark:border-gray-700 mb-4">
          <button
            onClick={() => setActiveTab("colors")}
            className={`py-2 px-4 text-sm font-medium ${
              activeTab === "colors"
                ? "text-my-blue border-b-2 border-my-blue"
                : "text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300"
            }`}
          >
            Colors
          </button>
          <button
            onClick={() => setActiveTab("style")}
            className={`py-2 px-4 text-sm font-medium ${
              activeTab === "style"
                ? "text-my-blue border-b-2 border-my-blue"
                : "text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300"
            }`}
          >
            Style
          </button>
          <button
            onClick={() => setActiveTab("effects")}
            className={`py-2 px-4 text-sm font-medium ${
              activeTab === "effects"
                ? "text-my-blue border-b-2 border-my-blue"
                : "text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300"
            }`}
          >
            Effects
          </button>
        </div>

        {/* Colors tab content */}
        {activeTab === "colors" && (
          <>
            <p className="text-sm text-gray-500 dark:text-gray-400 mb-3">
              Choose your favorite color scheme
            </p>

            <div className="grid grid-cols-3 gap-3 mb-4">
              {themeOptions.map((theme, index) => (
                <button
                  key={theme.name}
                  onClick={() => applyTheme(index)}
                  className={`relative w-full aspect-square rounded-md shadow-sm hover:shadow transition-shadow ${
                    selectedTheme === index
                      ? "ring-2 ring-gray-800 dark:ring-white"
                      : ""
                  }`}
                  aria-label={`Select ${theme.name} theme`}
                  title={theme.name}
                  style={{
                    background: theme.gradient,
                  }}
                >
                  {selectedTheme === index && (
                    <div className="absolute inset-0 flex items-center justify-center">
                      <Check
                        size={20}
                        weight="bold"
                        className="text-white drop-shadow-md"
                      />
                    </div>
                  )}
                </button>
              ))}
            </div>

            {/* Light/Dark mode toggle */}
            <div className="flex justify-between items-center p-2 bg-gray-100 dark:bg-gray-700 rounded-lg mb-3">
              <span className="text-sm text-gray-700 dark:text-gray-300">
                Dark Mode
              </span>
              <button
                onClick={toggleDarkMode}
                className="p-2 rounded-lg bg-white dark:bg-gray-600 shadow"
              >
                {isDarkMode ? (
                  <Sun size={20} className="text-yellow-500" />
                ) : (
                  <Moon size={20} className="text-gray-700" />
                )}
              </button>
            </div>

            {/* Random theme button */}
            <button
              onClick={randomizeTheme}
              className="w-full flex items-center justify-center gap-2 py-2 bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-white rounded-lg hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors"
            >
              <ArrowsClockwise size={18} />
              Randomize Theme
            </button>
          </>
        )}

        {/* Style tab content */}
        {activeTab === "style" && (
          <>
            <p className="text-sm text-gray-500 dark:text-gray-400 mb-3">
              Customize background and UI style
            </p>

            {/* Gradient styles */}
            <h4 className="font-medium text-sm text-gray-700 dark:text-gray-300 mb-2">
              Background Style
            </h4>
            <div className="grid grid-cols-2 gap-2 mb-4">
              {gradientStyles.map((style, index) => (
                <button
                  key={style.name}
                  onClick={() => applyGradient(index)}
                  className={`relative p-2 h-12 rounded-md text-xs font-medium text-center ${
                    selectedGradient === index
                      ? "ring-2 ring-my-blue"
                      : "ring-1 ring-gray-200 dark:ring-gray-700"
                  }`}
                  style={{
                    background: style.value
                      .replace(
                        "var(--color-primary-light)",
                        themeOptions[selectedTheme].primary + "33"
                      )
                      .replace(
                        "var(--color-secondary-light)",
                        themeOptions[selectedTheme].secondary + "33"
                      ),
                  }}
                >
                  <span className="relative z-10 text-gray-800 dark:text-white drop-shadow-sm">
                    {style.name}
                  </span>
                </button>
              ))}
            </div>

            {/* UI Modes */}
            <h4 className="font-medium text-sm text-gray-700 dark:text-gray-300 mb-2">
              UI Style
            </h4>
            <div className="grid grid-cols-2 gap-2 mb-4">
              {uiModes.map((mode, index) => (
                <button
                  key={mode.name}
                  onClick={() => applyUiMode(index)}
                  className={`p-2 rounded-md text-sm font-medium ${
                    selectedUiMode === index
                      ? "bg-my-blue text-white"
                      : "bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-white hover:bg-gray-200 dark:hover:bg-gray-600"
                  }`}
                >
                  {mode.name}
                </button>
              ))}
            </div>
          </>
        )}

        {/* Effects tab content */}
        {activeTab === "effects" && (
          <>
            <p className="text-sm text-gray-500 dark:text-gray-400 mb-3">
              Adjust animations and visual effects
            </p>

            {/* Animation level */}
            <h4 className="font-medium text-sm text-gray-700 dark:text-gray-300 mb-2">
              Animation Level
            </h4>
            <div className="mb-4">
              <input
                type="range"
                min="0"
                max="3"
                value={animationLevel}
                onChange={(e) => setAnimationLevel(parseInt(e.target.value))}
                className="w-full h-2 bg-gray-200 dark:bg-gray-700 rounded-lg appearance-none cursor-pointer accent-my-blue"
              />
              <div className="flex justify-between text-xs text-gray-500 dark:text-gray-400 mt-1">
                <span>None</span>
                <span>Subtle</span>
                <span>Medium</span>
                <span>High</span>
              </div>
            </div>

            {/* Special effects */}
            <h4 className="font-medium text-sm text-gray-700 dark:text-gray-300 mb-2">
              Special Effects
            </h4>
            <div className="space-y-2">
              <div className="flex items-center justify-between p-2 bg-gray-100 dark:bg-gray-700 rounded-lg">
                <span className="text-sm text-gray-700 dark:text-gray-300 flex items-center gap-2">
                  <Sparkle
                    size={16}
                    className={
                      showSparkles ? "text-yellow-400" : "text-gray-400"
                    }
                  />
                  Sparkle Elements
                </span>
                <button
                  onClick={toggleSparkles}
                  className={`w-10 h-5 flex items-center rounded-full ${
                    showSparkles ? "bg-my-blue" : "bg-gray-300 dark:bg-gray-600"
                  } p-1 transition-colors`}
                >
                  <div
                    className={`w-4 h-4 rounded-full bg-white transform transition-transform ${
                      showSparkles ? "translate-x-5" : "translate-x-0"
                    }`}
                  ></div>
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ThemeCustomizer;
