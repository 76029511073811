import React, { useEffect, useState } from "react";

interface ScrollProgressProps {
  height?: number;
  color?: string;
  isGradient?: boolean;
  showPercentage?: boolean;
  position?: "top" | "bottom";
}

const ScrollProgress: React.FC<ScrollProgressProps> = ({
  height = 4,
  color = "",
  isGradient = true,
  showPercentage = false,
  position = "top",
}) => {
  const [scrollPercentage, setScrollPercentage] = useState(0);

  useEffect(() => {
    const calculateScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollTop = window.scrollY;

      const percentage = (scrollTop / (documentHeight - windowHeight)) * 100;
      setScrollPercentage(Math.min(100, Math.max(0, percentage)));
    };

    window.addEventListener("scroll", calculateScroll);
    calculateScroll(); // Calculate initial value

    return () => window.removeEventListener("scroll", calculateScroll);
  }, []);

  const getStyles = () => {
    const positionStyles = position === "top" ? { top: 0 } : { bottom: 0 };

    const backgroundStyle = isGradient
      ? "var(--gradient-primary)"
      : color || "var(--color-primary)";

    return {
      ...positionStyles,
      height: `${height}px`,
      width: `${scrollPercentage}%`,
      background: backgroundStyle,
    };
  };

  return (
    <>
      <div
        className="fixed left-0 z-50 transition-all duration-100"
        style={getStyles()}
      />

      {showPercentage && (
        <div
          className={`fixed ${
            position === "top" ? "top-5" : "bottom-5"
          } right-5 z-50 bg-white dark:bg-gray-800 rounded-full shadow-lg px-3 py-1 text-xs font-semibold`}
        >
          {Math.round(scrollPercentage)}%
        </div>
      )}
    </>
  );
};

export default ScrollProgress;
