import React from "react";
import {
  LinkedinLogo,
  GithubLogo,
  EnvelopeSimple,
  Heart,
  CodeBlock,
  Coffee,
} from "@phosphor-icons/react";

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="w-full py-8 px-4 bg-white dark:bg-gray-800 border-t border-gray-200 dark:border-gray-700">
      <div className="container mx-auto max-w-7xl">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Column 1: About */}
          <div className="text-center md:text-left">
            <h3 className="text-lg font-semibold mb-3 text-gray-800 dark:text-white">
              Owen Kleinmaier
            </h3>
            <p className="text-gray-600 dark:text-gray-300 mb-4 text-sm">
              Computer Science student passionate about web development,
              artificial intelligence, and creating impactful technology.
            </p>
            <div className="flex justify-center md:justify-start space-x-4">
              <a
                href="https://www.linkedin.com/in/owen-kleinmaier"
                aria-label="LinkedIn"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-500 hover:text-my-blue transition-colors"
              >
                <LinkedinLogo size={24} weight="fill" />
              </a>
              <a
                href="https://github.com/owenkleinmaier"
                aria-label="GitHub"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-500 hover:text-gray-800 dark:hover:text-white transition-colors"
              >
                <GithubLogo size={24} weight="fill" />
              </a>
              <a
                href="mailto:owenkleinmaier@gmail.com"
                aria-label="Email"
                className="text-gray-500 hover:text-red-500 transition-colors"
              >
                <EnvelopeSimple size={24} weight="fill" />
              </a>
            </div>
          </div>

          {/* Column 2: Quick Links */}
          <div className="text-center">
            <h3 className="text-lg font-semibold mb-3 text-gray-800 dark:text-white">
              Quick Links
            </h3>
            <ul className="space-y-2 text-sm">
              <li>
                <a
                  href="#about"
                  className="text-gray-600 dark:text-gray-300 hover:text-my-blue dark:hover:text-my-blue transition-colors animated-underline"
                >
                  About Me
                </a>
              </li>
              <li>
                <a
                  href="#skills"
                  className="text-gray-600 dark:text-gray-300 hover:text-my-blue dark:hover:text-my-blue transition-colors animated-underline"
                >
                  My Skills
                </a>
              </li>
              <li>
                <a
                  href="#projects"
                  className="text-gray-600 dark:text-gray-300 hover:text-my-blue dark:hover:text-my-blue transition-colors animated-underline"
                >
                  Projects
                </a>
              </li>
              <li>
                <a
                  href="/resume"
                  className="text-gray-600 dark:text-gray-300 hover:text-my-blue dark:hover:text-my-blue transition-colors animated-underline"
                >
                  Resume
                </a>
              </li>
            </ul>
          </div>

          {/* Column 3: Contact */}
          <div className="text-center md:text-right">
            <h3 className="text-lg font-semibold mb-3 text-gray-800 dark:text-white">
              Get In Touch
            </h3>
            <p className="text-gray-600 dark:text-gray-300 mb-4 text-sm">
              Looking for summer 2025 internships.
              <br />
              Feel free to reach out!
            </p>
            <a
              href="mailto:owenkleinmaier@gmail.com"
              className="inline-flex items-center justify-center px-4 py-2 bg-my-blue text-white rounded-md hover:bg-blue-600 transition-colors"
            >
              <EnvelopeSimple size={18} className="mr-2" />
              Contact Me
            </a>
          </div>
        </div>

        {/* Copyright */}
        <div className="pt-8 mt-8 border-t border-gray-200 dark:border-gray-700 text-center text-sm text-gray-500 dark:text-gray-400">
          <p className="flex items-center justify-center">
            © {currentYear} Owen Kleinmaier. All rights reserved.
          </p>
          <p className="mt-2 flex items-center justify-center">
            <span>Made with</span>
            <Heart weight="fill" className="mx-1 text-red-500" size={16} />
            <span>using</span>
            <CodeBlock className="mx-1" size={16} />
            <span>and lots of</span>
            <Coffee className="mx-1" size={16} />
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
